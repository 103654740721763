:root {
    --main-color: #846cf9;
    --main-color-200: #d7d5ff;
    --main-color-300: #b9b4fe;
    --main-color-400: #9689fc;
    --main-color-600: #6237f0;
    --main-color-700: #5325dc;
    --green-color: #89B72B;
    --orange-color: #F8B01D;
    --black-color: #000000;
    --red-color: #ff6347;
    --white-color: #ffffff;
}

.main-col-new {
    background-color: var(--main-color) !important;
    color: white !important;
}

.cl-green {
    background-color: var(--green-color) !important;
}

.cl-orange {
    background-color: var(--orange-color) !important;
}

.btn-orange {
    background-color: var(--orange-color) !important;
    color: var(--black-color) !important;
    margin: 0px 15px !important;
}

.text-orange {
    color: var(--orange-color);
}

.text-green {
    color: var(--green-color);
}

.btn-danger {
    background-color: var(--red-color) !important;
    color: var(--white-color) !important;
    margin: 0px 15px !important;
}

.btn-green {
    background-color: var(--main-color) !important;
    color: var(--white-color) !important;
    margin: 0px 15px;
}

.btn-green-disable {
    background-color: var(--main-color-200) !important;
    color: var(--black-color) !important;
    margin: 0px 15px !important;
}

.btn-linear {
    background: rgb(137, 183, 43);
    background: linear-gradient(261deg, rgba(137, 183, 43, 1) 0%, rgba(248, 176, 29, 1) 100%);
    color: var(--black-color) !important;
    margin: 0px 15px !important;
}

.swal2-container {
    z-index: 9999 !important;
}

/* Notification */

.Notification-section {
    position: absolute;
    background: #fcfcfc;
    width: 350px;
    right: -86px;
    top: 140%;
    border-radius: 1px;
    overflow: hidden;
    z-index: 999;

}

.Notification-section .title {
    background-color: var(--main-color-400);
    padding: 15px 0px;
    color: white;
}

.Notification-section .message {
    font-size: 13px;
    background-color: #919eab1f;
    border-bottom: 1px solid #d3cff1;
    color: black;
}

.Notification-section .timing {
    font-size: 12px;
    color: #6d6d6d;
}

.Notification-section ul {
    height: 300px;
    overflow-y: scroll;
}

.Notification-section .end-title {
    color: black;
    padding: 6px 0px;
    background: #edeff1;
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes zoomIn {
    0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    }
    50% {
    opacity: 1;
    }
    }
    @keyframes zoomIn {
    0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    }
    50% {
    opacity: 1;
    }
    } 
/* Notification */